<template>
<v-container>
    <v-row  no-gutters>
      <v-col cols="12">
       
          <va-update v-if="$store.state.required_update" :system="system"/>
          <v-carousel cycle  hide-delimiter-background height="180" class="mt-4">
            <v-carousel-item
              v-for="(item,i) in items"
              :key="i"
              :src="$store.state.img_dir + item.src"
            ></v-carousel-item>
          </v-carousel>
       
      </v-col> 
    </v-row>
    <v-row  no-gutters class="mt-8 icon-text">
        <v-col cols="3" class="text-center">
          <div>
            <img :src="$store.state.img_dir+'play.png'" height="30px" class="ma-auto" @click="$router.push('/play-games').catch(err => {})"/>
          </div>
          <span class="font-weight-regular">PLAY</span>
        </v-col>
        <v-col cols="3" class="text-center">
          <div>
            <img :src="$store.state.img_dir+'buy.png'" height="30px"  class="ma-auto"  @click="$router.push('/buy-credits').catch(err => {})"/>
          </div>
          <span class="font-weight-regular">BUY</span>
        </v-col>
        <v-col cols="3" class="text-center">
          <div>
            <img :src="$store.state.img_dir+'ticket.png'" height="30px"  class="ma-auto" @click="$router.push('/sales').catch(err => {})"/>
          </div>
          <span class="font-weight-regular">TICKETS</span>
        </v-col>
        <v-col cols="3" class="text-center">
          <div>
            <img :src="$store.state.img_dir+'result.png'" height="30px"  class="ma-auto"  @click="$router.push('/results').catch(err => {})"/>
          </div>
          <span class="font-weight-regular">RESULTS</span>
        </v-col>
      </v-row>
      <v-row  no-gutters class="mt-4 icon-text">
        <v-col cols="3" class="text-center">
          <div>
            <img :src="$store.state.img_dir+'summary.png'" height="30px" class="ma-auto" @click="$router.push('/summary').catch(err => {})"/>
          </div>
          <span class="font-weight-regular">SUMMARY</span>
        </v-col>
        <v-col cols="3" class="text-center">
          <div>
            <img :src="$store.state.img_dir+'commission.png'" height="30px"  class="ma-auto" @click="$router.push('/commissions').catch(err => {})"/>
          </div>
          <span class="font-weight-regular">COMMISSION</span>
        </v-col>
        <v-col cols="3" class="text-center">
          <div>
            <img :src="$store.state.img_dir+'member.png'" height="30px"  class="ma-auto" @click="$router.push('/players').catch(err => {})"/>
          </div>
          <span class="font-weight-regular">MEMBERS</span>
        </v-col>
        <v-col cols="3" class="text-center">
          <div>
            <img :src="$store.state.img_dir+'cashout.png'" height="30px"  class="ma-auto" @click="$router.push('/cashout').catch(err => {})"/>
          </div>
          <span class="font-weight-regular">CASHOUT</span>
        </v-col>
      </v-row>
      <v-row class="mt-4 icon-text">
        <v-col cols="6" class="text-center">
          <div>
            <img :src="$store.state.img_dir+'ng.png'" width="100%" class="ma-auto" @click="$router.push('/number-games').catch(err => {})"/>
          </div>
          
        </v-col>
        <v-col cols="6" class="text-center">
          <div>
            <img :src="$store.state.img_dir+'cg.png'"  width="100%" class="ma-auto"  @click="$router.push('/color-game').catch(err => {})"/>
          </div>
        </v-col>
       
      </v-row>
    <va-soon :show="how" @DialogEvent="hEvent"/> 
</v-container>
</template>
<script>
//import { API_MIXINS } from '@/mixins/api_mixins.js'
import {
  mapMutations
} from 'vuex'
  export default {
   // mixins: [API_MIXINS],
    data: () => ({
      items: [
          {
            src:"banner.png",
          },
          {
            src:"banner2.png",
          },
          {
            src:"banner3.png",
          },
        ],
      watch: false,
      DRAWINTERVAL: null,
      next_draw:"",
      how:false,
      result: {},
        system: {},
    }),
    created() {
      this.PING()
        this.setData()
    },
     mounted(){
         this.setDrawer(false)
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
          this.SYSTEM_UPDATE()
         
        }
        
    },
    beforeDestroy(){
       if(this.CHECKINTERVAL != null) { clearInterval(this.CHECKINTERVAL)}
       return true
    },
    computed:{
     
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      RESULTS() {
        return this.$store.state.results.length>0?this.$store.state.results:[]
      },
       RESULT2D() {
         return this.GET_RESULT("2D")
      },
      //  RESULT3D() {
      //     return this.GET_RESULT("3D")
      // },
      // SILAY() {
      //     return this.GET_RESULT("STL-SILAY")
      // },
      // CITY() {
      //     return this.GET_RESULT("STL-ILOILO-CITY")
      // },
      // PROVINCE() {
      //     return this.GET_RESULT("STL-ILOILO-PROVINCE")
      // },
      // PERYA() {
      //     return this.GET_RESULT("PERYA-ANTIQUE")
      // },
      item_count() {
        return this.$store.state.tickets.length.toString()
      },
      item_count_total() {
          var count = this.$store.state.tickets.reduce((res,item)=>res += this.$IsNum(item.amount), 0)
          return count.toString()
      },
      OPEN_DRAWS() {
       return this.$store.state.open_draws
      },
       OPEN_LIVE_DRAWS() {
       return this.$store.state.open_live_draws
      }
    
    },
    methods: {
      ...mapMutations(['setDrawer', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading', 'setOverlayLoadingText', 'setOpenMachine','setWatchDraw', 'setLiveDrawStatus']),
    
      hEvent(){
        this.how= false
      },
      GET_LABEL(game) {
          var data
          var results =  this.$store.state.results.length>0?this.$store.state.results:[]
          for(var i=0; i<results.length;i++) {
            if(results[i].game == game) {
              
                data =  this.GAMENAME(results[i].game) + " - "  +  results[i].time  + " DRAW"
                break
            } 
          }
       
          return data
      },
       GET_RESULT(game) {
          var data
          var results =  this.$store.state.results.length>0?this.$store.state.results:[]
          for(var i=0; i<results.length;i++) {
            if(results[i].game == game) {
               var nums = results[i].combination.split(" - ")
                results[i].nums = nums
                data =  results[i]
                break
            } 
          }
       
          return data
      },
      get_system_update() {
            //this.setRequiredUpdate(false)
            this.$http.get("system_update").then(response => {
              if(response.data.status) {
                 this.system = response.data.system
              }
               }).catch(e => {
                console.log(e.data)
            })
        },
        setData() {
          this.CHECK_BALANCE(this.user.id)
          var param = {}
          param.account = this.user.id
          param.method = "per_account"
          param.from_date =  this.$moment().format("YYYY-MM-DD")
          this.GET_TICKETS(param)
          this.GET_RESULTS(param)
        
          setTimeout(()=>{this.get_system_update()}, 800)
      }
    },
  }
</script>